'use client'

import { RecoilRoot } from 'recoil'

export default function StoreRoot({
  children
}) {

  return (
    <RecoilRoot>
      {children}
    </RecoilRoot>
  )
}